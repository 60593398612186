import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import AppointmentsPage from 'pages/appointments';
import { setupLiff } from 'src/lib/liff';
import { fetchAndSaveAuthToken } from 'services/user.service';
import { setAuth } from 'src/services/utils';

export default function Home(props) {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;
        const t = await fetchAndSaveAuthToken({ userId, liffId });
        setAuth({ token: t, liffId, userId });
        setToken(t);
      })
      .catch(async (err) => {
        setMessage(err);
      });
  }, []);
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <AppointmentsPage {...props} {...message}></AppointmentsPage>
      </LineContext.Provider>
    );
  }
  return null;
}
